<template>
  <nav
    id="main-navbar"
    class="navbar navbar-expand-lg navbar-light fixed-top"
    :class="{ expand: expandNavbar, 'bg-white shadow-bottom': hasNavbarPad }"
  >
    <div class="container-fluid">
      <div class="px-0 py-2 px-md-2">
        <button
          class="navbar-toggler border-0 me-2"
          type="button"
          @click="expandNavbar = !expandNavbar"
        >
          <i
            class="toggler-icon fas fa-bars"
            :class="hasNavbarPad ? 'text-dark' : 'text-light'"
            v-if="!expandNavbar"
          />
          <i class="toggler-icon fas fa-times text-dark" v-else />
        </button>
        <router-link class="navbar-brand" to="/">
          <img src="@/assets/logo.png" class="logo-img" />
          <img
            src="@/assets/text.png"
            class="ms-3 logo-text d-none d-sm-inline-block"
          />
        </router-link>
      </div>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="d-lg-none display-6">
            <span class="badge bg-secondary w-100 py-3 mb-4">MENU</span>
          </li>
          <li
            class="nav-item lh-lg"
            v-for="route in routes.filter(
              (route) => route.meta && route.meta.includeInNavbar
            )"
            :key="route.name"
          >
            <router-link
              class="nav-link"
              :to="{ name: route.name }"
              :class="{
                active: $route.name == route.name,
                'text-light': !hasNavbarPad,
                'text-dark': hasNavbarPad,
              }"
              @click.native="expandNavbar = false"
            >
              {{ (route.meta && route.meta.title) || route.name }}
            </router-link>
          </li>
          <li class="nav-item d-flex align-items-center justify-content-center mt-3 mt-lg-0">
            <a
              class="btn btn-primary btn-sm border-light rounded-pill px-4"
              target="_blank"
              href="https://jazro.com.my"
            >
              <small>CORPORATE WEBSITE</small>
            </a>
          </li>
        </ul>
      </div>
      <navbar-user @click.native="expandNavbar = false" />
    </div>
  </nav>
</template>

<script>
import Routes from "@/router/routes";
import NavbarUser from "@/components/NavbarUser";

export default {
  components: {
    NavbarUser,
  },
  data() {
    return {
      routes: Routes,
      expandNavbar: false,
      hasNavbarPad: false,
    };
  },
  watch: {
    $route: {
      handler() {
        this.expandNavbar = false;

        this.$nextTick(() => {
          let navbarPad = document.getElementsByClassName("navbar-pad");

          this.hasNavbarPad = navbarPad.length > 0;
        });
      },
      immediate: true,
    },
    expandNavbar: {
      handler() {
        if (this.expandNavbar) {
          document.body.classList.add("overflow-hidden", "overflow-hidden-lg");
        } else {
          document.body.classList.remove(
            "overflow-hidden",
            "overflow-hidden-lg"
          );
        }

        this.$nextTick(() => {
          this.onScroll();
        });
      },
      immediate: false,
    },
  },
  methods: {
    onScroll() {
      const links = this.$el.querySelectorAll(".nav-link");

      const toggler = this.$el.querySelector(".toggler-icon");

      if (!this.hasNavbarPad) {
        if (window.scrollY >= this.$el.clientHeight) {
          this.$el.classList.add("bg-white", "shadow-bottom");

          toggler.classList.add("text-dark");

          links.forEach((link) => {
            link.classList.add("text-dark");
          });
        } else {
          this.$el.classList.remove("bg-white", "shadow-bottom");

          toggler.classList.remove("text-dark");

          links.forEach((link) => {
            link.classList.remove("text-dark");
          });
        }
      }
    },
  },
  mounted() {
    this.onScroll();

    window.addEventListener("scroll", this.onScroll);
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll);
  },
};
</script>