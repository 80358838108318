<template>
  <div id="app" class="position-relative">
    <main-navbar />
    <router-view />
    <main-footer />
    <chat-box />
  </div>
</template>

<script>
import ChatBox from "@/components/ChatBox";
import MainNavbar from "@/components/MainNavbar";
import MainFooter from "@/components/MainFooter.vue";

export default {
  components: {
    ChatBox,
    MainNavbar,
    MainFooter,
  },
  async mounted() {
    try {
      if (this.Helper.validateToken()) {
        const { data } = await this.API.get("users/me");

        if (data) {
          this.API.put(`users/${data._id}`, { lastLogin: Date.now() });
        }
      }
    } catch (error) {
      console.error(error);
    }
  },
};
</script>