<template>
  <div class="chat-box px-4" :class="{ expand: expandChatBox }">
    <button
      class="btn btn-primary chat-box-toggler px-4 rounded-pill"
      @click="toggleChatBox()"
    >
      <span class="d-none d-md-inline me-2">Need help</span>
      <i class="fas fa-question-circle" />
    </button>
    <button
      class="btn btn-primary btn-sm px-4 chat-box-header shadow"
      @click="toggleChatBox()"
    >
      Chat with Us
      <i class="fas fa-chevron-up chat-box-header-icon"></i>
    </button>
    <div class="chat-box-form bg-light p-3 shadow border">
      <div class="form-floating mb-3">
        <input
          type="text"
          class="form-control form-control-sm"
          :class="{ 'is-invalid': invalidFields['name'] }"
          id="chat-name"
          placeholder="Name"
          v-model="models.name"
        />
        <label for="chat-name">Name</label>
      </div>
      <div class="form-floating mb-3">
        <input
          type="email"
          class="form-control form-control-sm"
          :class="{ 'is-invalid': invalidFields['email'] }"
          id="chat-email"
          placeholder="Email"
          v-model="models.email"
        />
        <label for="chat-name">Email</label>
      </div>
      <div class="form-floating mb-3">
        <input
          type="tel"
          class="form-control form-control-sm"
          id="chat-phone"
          placeholder="Phone Number"
          @input="models.phone = models.phone.replace(/[^\d,]/g, '')"
          :class="{ 'is-invalid': invalidFields['phone'] }"
          v-model="models.phone"
        />
        <label for="chat-phone">Phone Number (optional)</label>
      </div>
      <div class="mb-3">
        <textarea
          class="form-control form-control-sm"
          id="chat-message"
          rows="8"
          placeholder="Message"
          :class="{ 'is-invalid': invalidFields['message'] }"
          v-model="models.message"
        />
      </div>
      <div class="mb-3">
        <button
          class="btn btn-secondary text-light w-100 btn-sm"
          @click="send()"
          :disabled="isLoading"
        >
          <span
            v-if="isLoading"
            class="spinner-border spinner-border-sm"
          ></span>
          <span v-else>Send</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";

export default {
  name: "component-chat-box",
  data() {
    return {
      isLoading: false,
      expandChatBox: false,
      models: {
        name: "",
        email: "",
        phone: "",
        message: "",
      },
      user: false,
      invalidFields: {},
    };
  },
  watch: {
    $route: {
      handler() {
        this.toggleChatBox("hide");
      },
      immediate: true,
      deep: true,
    },
    expandChatBox: {
      handler() {
        if (this.expandChatBox) {
          for (const key in this.models) {
            if (Object.hasOwnProperty.call(this.models, key)) {
              this.models[key] = "";
            }
          }

          this.invalidFields = {};

          this.$nextTick(() => {
            if (this.Helper.validateToken()) {
              this.getUser();
            } else {
              this.user = null;
            }
          });
        }
      },
      immediate: true,
    },
  },
  methods: {
    async getUser() {
      const user = this.$store.state.user;

      if (user) {
        this.user = user._id;

        for (const key in this.models) {
          if (Object.hasOwnProperty.call(this.models, key)) {
            this.models[key] = user[key];
          }
        }
      } else {
        this.user = null;
      }
    },
    send() {
      this.invalidFields = {};

      const emailValid = this.Helper.validateEmail(this.models.email);

      const phoneValid = this.models.phone
        ? this.Helper.validateTel(this.models.phone)
        : true;

      if (
        this.models.name.length >= 3 &&
        emailValid &&
        phoneValid &&
        this.models.message &&
        this.models.message.length >= 3
      ) {
        this.isLoading = true;

        let body = { ...this.models };

        if (this.user) {
          body.user = this.user;
        }

        this.API.post("enquiries", body)
          .then(() => {
            Swal.fire(
              "Message sent!",
              "Your message is successfully sent. Our staff will get back to you. Thanks!",
              "success"
            ).then(() => {
              this.toggleChatBox("hide");
            });
          })
          .finally(() => {
            this.isLoading = false;
          });
      } else {
        if (this.models.name.length < 3) {
          this.invalidFields.name = true;
        }

        if (!this.models.message || this.models.message.length < 3) {
          this.invalidFields.message = true;
        }

        if (!emailValid) {
          this.invalidFields.email = true;
        }

        if (!phoneValid) {
          this.invalidFields.phone = true;
        }

        alert("Invalid form submitted");
      }
    },
    toggleChatBox(type) {
      this.expandChatBox = type == "hide" ? false : !this.expandChatBox;

      this.$nextTick(() => {
        this.onResize();
      });
    },
    onResize() {
      if (this.$el.classList.contains("expand")) {
        this.$el.style.bottom = "0";
      } else {
        this.$el.style.bottom = `-${this.$el.clientHeight}px`;
      }
    },
  },
  mounted() {
    this.onResize();

    window.addEventListener("resize", this.onResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
};
</script>

<style lang="scss" scoped>
.chat-box-toggler {
  position: fixed;
  right: 2rem;
  bottom: 0.5rem;
  z-index: -1;
}

.chat-box {
  position: fixed;
  right: 0;
  max-width: 100%;
  max-height: 70%;
  height: 360px;
  width: fit-content;
  font-size: 0.9rem;
  transition: bottom 0.3s;
  z-index: 40;

  .chat-box-header-icon {
    transition: all 0.3s;
  }

  .chat-box-header {
    border-top-left-radius: 0.3rem;
    border-top-right-radius: 0.3rem;
    height: 48px;
    font-weight: 500;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    opacity: 0;
    transition: opacity 0.6s;
  }

  .chat-box-form {
    height: calc(100% - 48px);
    overflow-y: auto;
    opacity: 0;
    transition: opacity 0.6s;
  }

  &.expand {
    .chat-box-header-icon {
      transform: rotate(-180deg);
    }

    .chat-box-header {
      opacity: 1;
    }

    .chat-box-form {
      opacity: 1;
    }
  }
}
</style>