import Vue from "vue"
import Moment from "moment"
import Router from "@/router"
import Swal from "sweetalert2"

class Helper {
	static removeDuplicates(array) {
		return array.filter((value, index, self) => {
			return self.indexOf(value) === index;
		})
	}

	static alphabetSequence(number) {
		const alphabets = "abcdefghijklmnopqrstuvwxyz"

		if (number === null || number < 0) { return null }
		else {
			return alphabets[number]
		}
	}

	static removeBreakLine(text) {
		if (!text) {
			return ""
		}

		return text.split("<br />").map(t => t.trim()).join(" ")
	}

	static doSignOut() {
		Swal.fire({
			html: "Are you sure you want to sign out?",
			icon: "question",
			showCancelButton: true,
			cancelButtonText: "No, I want to stay",
			confirmButtonText: "Yes, please",
			reverseButtons: true,
			focusConfirm: false,
			focusCancel: true,
		}).then((result) => {
			if (result.isConfirmed) {
				window.scrollTo(0, 0)

				localStorage.clear()

				this.validateToken()

				if (Router.app._route.name != "Home") {
					Router.push({ name: "Home" })
				}
			}
		})
	}

	static defaultPassword = "Jazro123"

	static showLoginPrompt(returnUrl, disableBackdropClick = false, routeOnDismiss) {
		Swal.fire({
			title: "<h4 class='mb-0'>You are not signed in</h4>",
			html: "Please <span class='fw-medium'>sign in</span> or <span class='fw-medium'>create an account</span> to continue",
			icon: "info",
			backdrop: true,
			allowOutsideClick: !disableBackdropClick,
			showDenyButton: true,
			showCloseButton: true,
			denyButtonText: "Create account",
			confirmButtonText: "Sign In",
			customClass: {
				confirmButton: "bg-primary",
				denyButton: "bg-blue",
			},
		}).then((result) => {
			if (result.isDenied) {
				Router.replace({
					name: "SignUp",
					query: {
						returnUrl: returnUrl
					},
				});
			} else if (result.isConfirmed) {
				Router.replace({
					name: "SignIn",
					query: {
						returnUrl: returnUrl
					},
				})
			} else if (routeOnDismiss) {
				Router.replace(routeOnDismiss)
			}
		})
	}

	static generatePassword = () => {
		const alpha = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ";
		const numbers = "0123456789";
		const symbols = "!@#$%&*+=";

		let password = "";

		for (let i = 0; i < 4; i++) {
			password += alpha.charAt(
				Math.floor(Math.random() * alpha.length)
			);
		}

		for (let i = 0; i < 2; i++) {
			password += numbers.charAt(
				Math.floor(Math.random() * numbers.length)
			);
		}

		for (let i = 0; i < 1; i++) {
			password += symbols.charAt(
				Math.floor(Math.random() * symbols.length)
			);
		}

		return password;
	};

	static dispatchTokenChanged(result) {
		window.dispatchEvent(
			new CustomEvent("token-changed", {
				detail: {
					result: result
				},
			})
		);
	}

	static getLevelColor(level) {
		let color = "secondary"

		switch (level.toLowerCase()) {
			case "beginner":
				color = "teal-dark"
				break;
			case "intermediate":
				color = "cyan-dark"
				break;
			case "advance":
				color = "purple-dark"
				break;
			default:
				break;
		}

		return color;
	}

	static getEventStatus(event, exact) {
		const today = Moment().unix()

		const isCompetition = event.topic && event.topic.toLowerCase() == "competition"

		let start = isCompetition ?
			Moment().endOf("day").subtract(1, "day").unix() : Moment(event.start).unix()

		const end = isCompetition ?
			Moment(event.start).endOf("day").subtract(2, "day").unix() : Moment(event.end).unix()

		if (!exact) {
			start = start - 900 // 15 minutes before event start, set as ongoing
		}

		if (today < start) {
			// Belum jalan
			return "notYet"
		} else if (start < today && today < end) {
			// Tengah jalan
			return "ongoing"
		} else if (end < today) {
			// Dah habis
			return "expired"
		} else {
			// Entah
			return null
		}
	}

	static getAge = (birthDate, withText) => {
		if (!birthDate) {
			return null;
		}

		const age = Moment().diff(Moment(birthDate).startOf('year'), 'years');

		return withText ? age.toString().concat(" years old") : age;
	}

	static userInitial = (user) => {
		if (user) {
			let user_name = user.name;

			if (!user_name) return "ME";

			let result = "";

			user_name.replace(/[^a-zA-Z0-9_]+/g, " ").split(" ").forEach(name => {
				result += name.split("")[0];
			});

			return result.slice(0, 2);
		} else {
			return "";
		}
	}

	static formatNavLink = (link) => {
		if (link) {
			let linkArray = link;

			if (linkArray.includes(".")) {
				linkArray = linkArray.split(".");

				linkArray.shift();
			} else {
				linkArray = linkArray.split(" ");
			}

			linkArray = linkArray.join("-").split("-");

			linkArray.forEach((link, index) => {
				linkArray[index] = link.split("")[0].toUpperCase().concat(link.substring(1));
			});

			return linkArray.join(" ");
		} else {
			return "";
		}
	}

	static titleCase = (word, isSingle) => {
		if (word) {
			let wordArray = word.split(" ").join("_").split("_").join("-").split("-");

			wordArray.forEach((word, index) => {
				wordArray[index] = word.split("")[0].toUpperCase().concat(word.substring(1));
			});

			return isSingle ? wordArray[0].toString() : wordArray.join(" ");
		} else {
			return "";
		}
	}

	static formatDate = (date, withTime, multiline) => {
		if (!date) {
			return "";
		} else if (withTime) {
			if (typeof withTime == "boolean" && withTime === true) {
				return multiline ?
					`${Moment(date).format("D MMM YYYY")}<br />${Moment(date).format("h:mm A")}` :
					Moment(date).format("D MMM YYYY, h:mm A");
			} else {
				return Moment(date).format(withTime)
			}
		} else {
			return Moment(date).format("D MMM YYYY");
		}
	}

	static formatMoney = (data, returnText) => {
		if (!data) {
			return returnText ? "Free" : "RM 0.00";
		} else {
			return `RM ${data.toFixed(2)}`;
		}
	}

	static getMediaMimeType = (media) => {
		if (media && media.mime) {
			return media.mime;
		} else {
			return null;
		}
	}

	static formatMediaUrl = (media) => {
		if (media && media.url) {
			let url = media.url;

			return url.startsWith("http") ? url : `https://${url}`;
		} else {
			return false;
		}
	}

	static formatCoordinate = (coord) => {
		if (coord && typeof coord == "object" && coord.length == 2) {
			return `${parseFloat(coord[0]).toFixed(6)}, ${parseFloat(coord[1]).toFixed(6)}`;
		}
		if (coord && !isNaN(coord)) {
			return `${parseFloat(coord).toFixed(6)}`;
		} else {
			return "";
		}
	}

	static formatAddress = (user, multiline) => {
		if (!user) return "";

		let result = [];

		if (user.address1) {
			result.push(user.address1);
		}

		if (user.address2) {
			result.push(user.address2);
		}

		if (user.postcode) {
			result.push(user.postcode);
		}

		if (user.city) {
			result.push(user.city);
		}

		if (user.state) {
			result.push(user.state.replace("_", " "));
		}

		if (user.country) {
			result.push(user.country);
		}

		return result.join(multiline ? ",<br />" : ", ");
	}

	static validateEmail = (email) => {
		let pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);

		if (!pattern.test(email)) {
			return false;
		}

		return true;
	}

	static validateTel = (tel) => {
		let pattern = new RegExp(/^(\+?6?01)[0-46-9]-*[0-9]{7,8}$/g);

		if (!pattern.test(tel)) {
			return false;
		}

		return true;
	}

	static validatePostcode = (postcode) => {
		let pattern = new RegExp(/^\d{5}$/g);

		if (!pattern.test(postcode)) {
			return false;
		}

		return true;
	}

	static validatePassword = (password) => {
		// Minimum 7 characters.
		let pattern = new RegExp(/^(?=.*[a-zA-Z]).{7,}$/);

		if (!pattern.test(password)) {
			return false;
		}

		return true;
	}

	static validateToken = () => {
		const token = localStorage.getItem("token");

		let result = false;

		if (!token) {
			localStorage.clear();

			result = false;
		} else {
			let tokenSplit = token.split(".");

			if (tokenSplit.length != 3) {
				localStorage.clear();

				result = false;
			} else {
				let decodedHeader = atob(tokenSplit[0]);

				if (decodedHeader && JSON.parse(decodedHeader).typ == "JWT") {
					result = true;
				} else {
					localStorage.clear();

					result = false;
				}
			}
		}

		this.dispatchTokenChanged(result);

		return result;
	}
}

export default {
	install: () => {
		Vue.prototype.Helper = Helper
		Vue.Helper = Helper
	}
}