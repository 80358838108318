export default [{
	path: '/',
	name: 'Home',
	component: () => import('@/views/Home'),
	meta: {
		includeInNavbar: true,
	}
}, {
	path: '/sign-up',
	name: 'SignUp',
	redirect: { name: 'SignUp-CRT' }
	// component: () => import('@/views/SignUp'),
	// meta: {
	// 	includeInNavbar: false,
	// 	title: 'Sign Up',
	// 	guest: true
	// }
}, {
	path: '/sign-up/crt',
	name: 'SignUp-CRT',
	component: () => import('@/views/SignUp'),
	meta: {
		includeInNavbar: false,
		title: 'Sign Up - JAZRO Certified Robotic Tutor',
		guest: true
	}
}, {
	path: '/sign-in',
	name: 'SignIn',
	component: () => import('@/views/SignIn'),
	meta: {
		includeInNavbar: false,
		title: 'Sign In',
		guest: true
	}
}, {
	path: '/events',
	name: 'Events',
	component: () => import('@/views/Events'),
	meta: {
		includeInNavbar: true
	},
}, {
	path: '/events/:id?',
	name: 'EventDetails',
	component: () => import('@/views/EventDetails'),
	meta: {
		includeInNavbar: false
	},
}, {
	path: '/classes',
	name: 'Classes',
	component: () => import('@/views/Classes'),
	meta: {
		includeInNavbar: true
	}
}, {
	path: '/our-tutors',
	name: 'OurTutors',
	component: () => import('@/views/OurTutors'),
	meta: {
		includeInNavbar: true,
		title: 'Our Tutors'
	}
}, {
	path: '/admin',
	name: 'Admin',
	component: () => import('@/views/Admin'),
	meta: {
		includeInNavbar: false,
		requiresAuth: true
	},
	children: [{
		path: 'manage-users',
		name: "Admin.ManageUsers",
		component: () => import('../views/Admin/ManageUsers'),
		meta: {
			title: "Manage Users"
		}
	}, {
		path: 'manage-events',
		name: "Admin.ManageEvents",
		component: () => import('../views/Admin/ManageEvents'),
		meta: {
			title: "Manage Events"
		}
	}, {
		path: 'manage-classes',
		name: "Admin.ManageClasses",
		component: () => import('../views/Admin/ManageClasses'),
		meta: {
			title: "Manage Classes"
		}
	}, {
		path: 'manage-assessments',
		name: "Admin.ManageAssessments",
		component: () => import('../views/Admin/ManageAssessments'),
		meta: {
			title: "Manage Assessments"
		}
	}, {
		path: 'manage-tutor-assessments',
		name: "Admin.ManageTutorAssessments",
		component: () => import('../views/Admin/ManageTutorAssessments'),
		meta: {
			title: "Tutor Assessments"
		}
	}, {
		path: 'manage-enquiries',
		name: "Admin.ManageEnquiries",
		component: () => import('../views/Admin/ManageEnquiries'),
		meta: {
			title: "Manage Enquiries"
		}
	}]
}, {
	path: '/my-account',
	name: 'MyAccount',
	component: () => import('@/views/MyAccount'),
	meta: {
		includeInNavbar: false,
		requiresAuth: true
	},
	children: [{
		path: 'dashboard',
		name: "MyAccount.Dashboard",
		component: () => import('../views/MyAccount/Dashboard'),
		meta: {
			title: "Dashboard"
		}
		// }, {
		// 	path: 'booked-events',
		// 	name: "MyAccount.BookedEvents",
		// 	component: () => import('../views/MyAccount/BookedEvents'),
		// 	meta: {
		// 		title: "Booked Events",
		// 	}
	}, {
		path: 'dependants/:id?',
		name: "MyAccount.Dependants",
		component: () => import('../views/MyAccount/Dependants'),
		meta: {
			title: "My Dependants",
		}
	}, {
		path: 'profile',
		name: "MyAccount.Profile",
		component: () => import('../views/MyAccount/Profile'),
		meta: {
			title: "My Profile",
		}
	}]
}, {
	path: '/crt',
	name: 'CRT',
	component: () => import('@/views/CRT'),
	meta: {
		includeInNavbar: false,
		requiresAuth: true
	},
	children: [{
		path: 'home',
		name: "CRT.Home",
		component: () => import('../views/CRT/Home'),
		meta: {
			title: "Home"
		}
	}, {
		path: 'assessments',
		name: "CRT.Assessments",
		component: () => import('../views/CRT/Assessments'),
		meta: {
			title: "Assessments",
		}
	}, {
		path: 'certificates',
		name: "CRT.Certificates",
		component: () => import('../views/CRT/Certificates'),
		meta: {
			title: "Certificates",
		}
	}, {
		path: 'profile',
		name: "CRT.Profile",
		component: () => import('../views/MyAccount/Profile'),
		meta: {
			title: "My Profile",
		}
	}]
}, {
	path: '*',
	name: 'PageNotFound',
	component: () => import('@/views/PageNotFound'),
	meta: {
		includeInNavbar: false,
	}
}]