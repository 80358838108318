<template>
  <div v-if="!isLoading && !user">
    <router-link
      class="px-4 btn btn-sm btn-light border-dark rounded-pill me-2"
      :to="{
        name: 'SignIn',
        query: noReturnPath.includes($route.name)
          ? {}
          : {
              returnUrl: $route.path,
            },
      }"
    >
      Sign In
    </router-link>
    <div class="dropdown d-inline">
      <button
        class="
          px-4
          btn btn-sm btn-dark
          border-light
          rounded-pill
          text-nowrap
          d-inline-flex
          justify-content-between
          align-items-center
          dropdown-toggle
        "
        type="button"
        id="dropdownSignUp"
        data-bs-toggle="dropdown"
        data-bs-offset="10,20"
        aria-expanded="false"
      >
        <span class="me-2">Register</span>
      </button>
      <ul
        class="dropdown-menu dropdown-menu-end py-0"
        aria-labelledby="dropdownSignUp"
      >
        <!-- <li>
          <router-link
            class="dropdown-item py-2"
            :to="{
              name: 'SignUp',
              query: noReturnPath.includes($route.name)
                ? {}
                : {
                    returnUrl: $route.path,
                  },
            }"
          >
            <i class="fas fa-user-plus me-2" />User
          </router-link>
        </li> -->
        <li>
          <router-link
            class="dropdown-item bg-primary text-light py-2"
            :to="{
              name: 'SignUp-CRT',
              query: noReturnPath.includes($route.name)
                ? {}
                : {
                    returnUrl: $route.path,
                  },
            }"
          >
            <i class="fas fa-chalkboard-teacher me-2" />CRT
          </router-link>
        </li>
      </ul>
    </div>
  </div>
  <div v-else-if="!isLoading && user" class="dropdown nav-user-dropdown">
    <template v-if="user.role && user.role.name == 'Admin'">
      <button
        class="btn btn-secondary no-pseudo dropdown-toggle"
        type="button"
        id="dropdownUserMenu"
        data-bs-toggle="dropdown"
        aria-expanded="false"
      >
        {{ Helper.userInitial(user) }}
      </button>
      <ul
        class="dropdown-menu dropdown-menu-end"
        aria-labelledby="dropdownUserMenu"
      >
        <li>
          <p class="dropdown-header mb-0 fs-6 text-truncate">
            <small>{{ user.name }}</small>
          </p>
        </li>
        <li class="dropdown-divider"></li>
        <li>
          <router-link class="dropdown-item" :to="{ name: 'Admin' }">
            <i class="fas fa-user-shield fa-fw me-2" />
            Admin Section
          </router-link>
        </li>
        <li>
          <button class="dropdown-item" @click="Helper.doSignOut()">
            <i class="fas fa-sign-out-alt fa-fw me-2" />
            Sign Out
          </button>
        </li>
      </ul>
    </template>
    <template v-if="user.role && user.role.name == 'Authenticated'">
      <router-link
        :to="{ name: user.isCRT ? 'CRT' : 'MyAccount' }"
        class="btn rounded-pill overflow-hidden px-4 border shadow-sm"
        :class="
          $route.name && $route.name.includes(user.isCRT ? 'CRT' : 'MyAccount')
            ? 'btn-secondary'
            : 'btn-light'
        "
      >
        My Account
      </router-link>
    </template>
  </div>
  <button class="btn btn-sm btn-light rounded-pill" v-else-if="isLoading">
    <span class="spinner-border spinner-border-sm"></span>
  </button>
</template>

<script>
export default {
  data() {
    return {
      isLoading: false,
      user: null,
      noReturnPath: ["SignIn", "SignUp", "SignUp-CRT", "Home", "OurTutors"],
    };
  },
  methods: {
    async getUser() {
      if (!this.user) {
        this.isLoading = true;
      }

      try {
        const { data } = await this.API.get("users/me");

        if (data) {
          this.user = data;

          this.$store.commit("saveUser", this.user);
        }
      } catch (err) {
      } finally {
        this.isLoading = false;
      }
    },
    validateToken() {
      if (this.Helper.validateToken()) {
        this.getUser();
      } else {
        this.user = null;

        this.$store.commit("saveUser", null);
      }
    },
  },
  mounted() {
    this.validateToken();

    window.addEventListener("token-changed", (data) => {
      if (data.detail && data.detail.result) {
        if (!this.isLoading) {
          this.getUser();
        }
      } else {
        this.user = null;

        this.$store.commit("saveUser", null);
      }
    });
  },
};
</script>