<template>
  <div class="shadow-top">
    <footer class="container mx-auto">
      <div class="row mx-0 mb-3">
        <div class="col-12 col-lg-4 py-2">
          <p class="fw-medium">JAZRO TECHNOLOGY ROBOTICS SDN BHD</p>
          <p class="text-nowrap">
            <i class="fas fa-at me-2"></i>
            <a
              href="mailto:hello@jazro.com.my"
              class="text-dark text-decoration-none"
            >
              hello@jazro.com.my
            </a>
          </p>
          <p class="text-nowrap mb-0">
            <i class="fas fa-phone-square-alt me-2"></i>
            <a href="tel:+60185778242" class="text-dark text-decoration-none">
              +60185778242
            </a>
          </p>
        </div>
        <hr class="col-12 my-4 d-block d-lg-none" />
        <div class="col-12 col-md-6 col-lg-4 py-2">
          <p class="fw-medium">LINKS</p>
          <p
            class="text-nowrap"
            v-for="route in routes.filter(
              (route) => route.meta && route.meta.includeInNavbar
            )"
            :key="route.name"
          >
            <router-link
              :to="{ name: route.name }"
              class="text-reset text-decoration-none"
            >
              {{ (route.meta && route.meta.title) || route.name }}
            </router-link>
          </p>
          <p>
            <a
              class="btn btn-link p-0 text-decoration-none text-reset"
              href="https://www.jazro.com.my/investor"
              target="_blank"
            >
              Invest in JAZRO
            </a>
          </p>
          <p class="mb-0">
            <a
              class="btn btn-link p-0 text-decoration-none text-reset"
              href="https://www.jazro.com.my/about-us"
              target="_blank"
            >
              About Us
            </a>
          </p>
        </div>
        <hr class="col-12 my-4 d-block d-md-none" />
        <div class="col-12 col-md-6 col-lg-4 py-2">
          <p class="fw-medium text-center text-md-start">SOCIAL MEDIA</p>
          <div class="social-media text-center text-md-start">
            <a
              href="https://www.youtube.com/channel/UCg5RYpgKWOClY_o22BE5UIw"
              target="_blank"
            >
              <img src="@/assets/icons/youtube.png" alt="Youtube" />
            </a>
            <a
              class="whatsapp"
              href="https://wa.me/60185778242"
              target="_blank"
            >
              <img src="@/assets/icons/whatsapp.png" alt="WhatsApp" />
            </a>
            <a href="https://www.facebook.com/jazarirobot" target="_blank">
              <img src="@/assets/icons/facebook.png" alt="Facebook" />
            </a>
            <a href="https://www.instagram.com/jazarirobot" target="_blank">
              <img src="@/assets/icons/instagram.png" alt="Instagram" />
            </a>
          </div>
        </div>
      </div>
    </footer>
    <div class="p-1 border-top"></div>
    <div class="row mx-0 p-3">
      <div class="col-12 col-sm-6 text-sm-start text-center">
        <div class="flex navbar-brand me-0">
          <img src="@/assets/logo.png" class="logo-img" />
          <img src="@/assets/text.png" class="logo-text ms-2" />
        </div>
      </div>
      <div class="col-12 col-sm-6 text-center text-sm-end align-self-center">
        &copy; {{ new Date().getFullYear() }} Jazro. All Rights Reserved.
      </div>
    </div>
  </div>
</template>

<script>
import Routes from "@/router/routes";

export default {
  data() {
    return {
      routes: Routes,
    };
  },
};
</script>

<style lang="scss" scoped>
footer {
  padding: 2rem 1.5rem 0 1.5rem;

  .social-media {
    a {
      display: inline-block;
      height: 2rem;
      margin: 0.5rem 1rem;

      &.whatsapp {
        height: 2.5rem;
      }

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }

      img {
        height: 100%;
        width: auto;
        object-fit: contain;
      }
    }
  }
}
</style>