import Vue from 'vue'
import App from './App.vue'
import router from './router'

import VueAnalytics from 'vue-analytics'
import VueGtm from '@gtm-support/vue2-gtm'

import VCalendar from 'v-calendar'
import '@fortawesome/fontawesome-free/css/all.css'
import 'bootstrap/dist/js/bootstrap'
import './assets/main.scss'
import API from './utils/api'
import Helper from './utils/helper'
import store from './utils/store'

Vue.config.productionTip = false

Vue.use(VueAnalytics, {
  id: 'UA-144595951-4',
  router
})

Vue.use(VueGtm, {
  id: 'GTM-K6RG335',
  defer: false,
  compatibility: false,
  nonce: '2726c7f26c',
  enabled: true,
  debug: false,
  loadScript: true,
  vueRouter: router,
  ignoredViews: [],
  trackOnNextTick: false
})

Vue.use(API)
Vue.use(Helper)
Vue.use(VCalendar, {})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')