var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.isLoading && !_vm.user)?_c('div',[_c('router-link',{staticClass:"px-4 btn btn-sm btn-light border-dark rounded-pill me-2",attrs:{"to":{
      name: 'SignIn',
      query: _vm.noReturnPath.includes(_vm.$route.name)
        ? {}
        : {
            returnUrl: _vm.$route.path,
          },
    }}},[_vm._v(" Sign In ")]),_c('div',{staticClass:"dropdown d-inline"},[_vm._m(0),_c('ul',{staticClass:"dropdown-menu dropdown-menu-end py-0",attrs:{"aria-labelledby":"dropdownSignUp"}},[_c('li',[_c('router-link',{staticClass:"dropdown-item bg-primary text-light py-2",attrs:{"to":{
            name: 'SignUp-CRT',
            query: _vm.noReturnPath.includes(_vm.$route.name)
              ? {}
              : {
                  returnUrl: _vm.$route.path,
                },
          }}},[_c('i',{staticClass:"fas fa-chalkboard-teacher me-2"}),_vm._v("CRT ")])],1)])])],1):(!_vm.isLoading && _vm.user)?_c('div',{staticClass:"dropdown nav-user-dropdown"},[(_vm.user.role && _vm.user.role.name == 'Admin')?[_c('button',{staticClass:"btn btn-secondary no-pseudo dropdown-toggle",attrs:{"type":"button","id":"dropdownUserMenu","data-bs-toggle":"dropdown","aria-expanded":"false"}},[_vm._v(" "+_vm._s(_vm.Helper.userInitial(_vm.user))+" ")]),_c('ul',{staticClass:"dropdown-menu dropdown-menu-end",attrs:{"aria-labelledby":"dropdownUserMenu"}},[_c('li',[_c('p',{staticClass:"dropdown-header mb-0 fs-6 text-truncate"},[_c('small',[_vm._v(_vm._s(_vm.user.name))])])]),_c('li',{staticClass:"dropdown-divider"}),_c('li',[_c('router-link',{staticClass:"dropdown-item",attrs:{"to":{ name: 'Admin' }}},[_c('i',{staticClass:"fas fa-user-shield fa-fw me-2"}),_vm._v(" Admin Section ")])],1),_c('li',[_c('button',{staticClass:"dropdown-item",on:{"click":function($event){return _vm.Helper.doSignOut()}}},[_c('i',{staticClass:"fas fa-sign-out-alt fa-fw me-2"}),_vm._v(" Sign Out ")])])])]:_vm._e(),(_vm.user.role && _vm.user.role.name == 'Authenticated')?[_c('router-link',{staticClass:"btn rounded-pill overflow-hidden px-4 border shadow-sm",class:_vm.$route.name && _vm.$route.name.includes(_vm.user.isCRT ? 'CRT' : 'MyAccount')
          ? 'btn-secondary'
          : 'btn-light',attrs:{"to":{ name: _vm.user.isCRT ? 'CRT' : 'MyAccount' }}},[_vm._v(" My Account ")])]:_vm._e()],2):(_vm.isLoading)?_c('button',{staticClass:"btn btn-sm btn-light rounded-pill"},[_c('span',{staticClass:"spinner-border spinner-border-sm"})]):_vm._e()}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{staticClass:"\n        px-4\n        btn btn-sm btn-dark\n        border-light\n        rounded-pill\n        text-nowrap\n        d-inline-flex\n        justify-content-between\n        align-items-center\n        dropdown-toggle\n      ",attrs:{"type":"button","id":"dropdownSignUp","data-bs-toggle":"dropdown","data-bs-offset":"10,20","aria-expanded":"false"}},[_c('span',{staticClass:"me-2"},[_vm._v("Register")])])}]

export { render, staticRenderFns }